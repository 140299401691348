<template>
  <div>
    <CostsChart />
    <CostsSummary />
  </div>
</template>

<script>

import CostsChart from './CostsChart.vue'
import CostsSummary from './CostsSummary.vue'

export default {
  components: {
    CostsChart,
    CostsSummary,
  },
}
</script>

<style>

</style>
